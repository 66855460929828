import React from "react";
import { graphql } from "gatsby";
import Seo from "../../components/Seo";
import Layout from "../../layouts/index";
import n8 from "../../images/news8.jpg";
import Skwirk from "../../images/skiwirklg.jpg";
import Jordan from "../../images/Jordan.jpg";
import aws from "../../images/aws-300x180.png";
import NFooter from "../../components/nFooter";

// import CommentsForm from '../../components/CommentsForm';

const News = (props) => {
  return (
    <Layout bodyClass="page-news noteworthy">
      <Seo
        title="How Migrating to AWS Eliminated Tech Worries at Skwirk"
        description="How migrating to AWS eliminated tech worries at Skwirk and how iTelaSoft helped the education platform to have 99.9% uptime"
      />

      <div className="intro intro-banner" id="up">
        <div className="intro-banner-img news8-bnr">
          <img alt="Eliminated tech worries" src={n8} />
        </div>
        <div className="container container-narrow intro-title noteworthy-title">
          <div className="row">
            <div className="col-12">
              <div className="date">CASE STUDY | FEBRUARY 5, 2020</div>
              <h1>How migrating to AWS eliminated tech worries at Skwirk</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container skwirk-page pt-4 pt-md-5 mb-6">
        <div className="row justify-content-start">
          <div className="col-12">
            <div className="p-top">
              <p>
                It was in early 2014 that iTelaSoft took on the responsibility
                of managing Skwirk online learning platform a leader in
                delivering K-12 curriculum based content to students and
                teachers of Australia and the UK schools. It had great content
                developed by amazing pedagogical and instructional design
                talent, which made it such a powerful tool loved by teachers and
                students alike. The demand for its service was souring and
                business was planning to scale-up.
              </p>
              <div className="skwirk">
                <img alt="Skwirk" src={Skwirk} />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-4 col-12 p-mid">
              <h5 className="mb-2">What Skwirk had to say</h5>
              <p>
                iTelaSoft consistently deliver to us a high quality result from
                both our development and support tasks. We have had a long term
                relationship and found that the staff are able to enhance our
                own skills and to communicate to us the effectiveness of facets
                of our development tasks, as well as identifying areas where the
                task needs to strengthen.
              </p>
              <div className="text-center">
                <img alt="Jordan Butler" src={Jordan} />
              </div>
              <div className="wrap-small">
                <div className="small">
                  <i>
                    Jordan Butler, General Manager of Skwirk Online Education
                  </i>
                </div>
              </div>
            </div>
            <div className="col-md-8 col-12">
              <p>
                However the Achilles heel of Skwirk was the tech platform behind
                it. It was bursting at its seams under heavy peak time traffic.
                The state of the platform was not in good shape. It would crash
                during peak hours and sometimes there would be loss of data.
                Furious users inundated support lines with frustration and
                threatening to cancel their subscriptions. Developers and
                engineers would burn time in fire fighting instead of adding
                value to the product. It was obvious that something had to be
                done, and it had to be done pretty quickly or risk of being
                abandoned by the users.
              </p>
              <p>
                iTelaSoft as the new tech force behind the platform was faced
                with the challenge of recommending a viable solution. After
                evaluating multiple options, it became obvious that shifting of
                workloads to the cloud to be the best option. That would allow
                the platform to be highly available while addressing
                scalability, reliability and performance issues. Having studied
                the application architecture in depth we became convinced that
                this will also allow the platform to be set on the right
                trajectory for its future growth. Amazon Web Services was chosen
                as the cloud platform due to its completeness of service
                offerings compared to other platforms. Also RDS, EC2 and
                S3-Cloud front combination was an ideal fit for a content
                delivery platform. Quickly we devised the target architecture
                and costed the transition cost and operational expenses with a
                view on minimising the total cost of ownership. The new
                architecture would allow us to leverage availability zones, load
                balancing, provide auto scaling and 99.9% uptime, exceed
                reliability and performance goals, zero downtime maintenance. A
                proposal was prepared with a target architecture documented and
                a roadmap to transform the entire application. Its initial step
                was to lift and shift the application to the cloud with as
                little code changes as possible. It would also take care of
                business continuity and data security objectives. The business
                case was so compelling we were immediately given the go ahead.
              </p>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-4 col-12 p-mid-two">
              <div className="text-center">
                <img alt="AWS" src={aws} />
              </div>
            </div>

            <div className="col-md-8 col-12">
              <p>
                After 3 weeks of minor application changes, engineering,
                provisioning and 2 further weeks of data and content migration
                and testing exercise, the platform was ready for taking its
                initial workload. An incremental migration strategy was devised
                where we would gradually migrate user accounts to the brand new
                environment. Apart from minor data migration related issues
                which were corrected swiftly, the results were positive. After 6
                weeks of incrementally shifting hundreds of thousands of
                accounts finally we could look back, review and start monitoring
                service quality of the new setup. To everyone’s relief it was a
                great success. There was no more crashing and data losses, and
                the content was being delivered snap fast that the user
                sentiment turned around almost immediately. Skwirk was back in
                business and could start delivering valuable product features
                again.
              </p>
            </div>
          </div>
          <div className="col-12">
            <p>
              Now the developers empowered with all the Dev-Ops options of AWS
              could automate and accelerate feature releases. Although the
              original application ported was not designed for the cloud, the
              effort provided a great launch-pad for the next phase of
              re-architecting Skwirk to fully leverage AWS’s capabilities.
            </p>
            <p>
              Albeit done with a hurry, over time, moving to AWS proved to be a
              great decision. As the platform grew, it was re-architected to be
              cloud native. The original monolithic application was broken-up
              and transformed to run on well architected domain APIs which were
              backed by domain based database schemas. It could leverage AWS‘s
              SQS for queuing, API Gateway and lambda functions for various
              value added APIs and integrations.
            </p>
            <p>
              As I write this article Skwirk is now working on another round of
              innovation leveraging AWS’s Elasticsearch and Machine Learning
              services, which will allow the platform to maintain its
              competitive edge and continue delivering exceptional value to the
              community.
            </p>
          </div>
        </div>
        {/* <CommentsForm /> */}
        <NFooter
          prv1="/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
          prv2="/noteworthy/iTelaSoft-CEO-Honored-at-the-IESL-NSW-Awards"
          next1="/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
          next2="/noteworthy/iTelaSoft-announces-the-release-of-5G-capabilities-on-its-XG-platform"
          titlel="iTelaSoft CEO Honored at the IESL NSW Awards"
          titler="iTelaSoft Announces the Release of 5G Capabilities on its XG Platform"
        />
      </div>
    </Layout>
  );
};
export default News;
